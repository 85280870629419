export enum StationType {
  Sale = 'Sale',
  Validator = 'Validator',
}

export enum StationStatus {
  Ready = 'Ready',
  InUse = 'InUse',
  Sleeping = 'Sleeping',  // will remove after discussion
  Resting = 'Resting',
  Disabled = 'Disabled',
  Disconnected = 'Disconnected',
  Diagnostic = 'Diagnostic',
  AssistanceNeeded = 'AssistanceNeeded',
  ShopperAssistance = 'ShopperAssistance',
  Maintenance = 'Maintenance',
  RegisteredAwaitingConnection = 'RegisteredAwaitingConnection',
}

export interface StatusDataType {
  name: string;
  text: string;
  textColor: string;
  color: string;
}
