import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useSettings from 'src/hooks/useSettings';
import { useSelector } from 'src/store';
import { Pos } from 'src/types/pos';
import { SocketDataInterface } from 'src/types/socket';
import { StationType } from 'src/types/station';
import { useLocation } from 'react-router-dom';

interface SquareGuardProps {
  children?: ReactNode;
}

const SquareGuard: FC<SquareGuardProps> = ({ children }) => {
  const { pathname } = useLocation();
  const { socketData, isConnected }: SocketDataInterface = useSelector((state) => state.socket);
  const squareToken = socketData?.posApiKey;
  const posDeviceIdentifier = socketData?.posDeviceIdentifier;
  const { settings } = useSettings();
  const pos = settings?.pos;
  const navigate = useNavigate();

  if (isConnected && socketData?.stationType === StationType.Sale) {
    if (pos === Pos.Square && !squareToken && pathname !== '/square/auth') {
      navigate('/square/auth');
    } else if (pos === Pos.Square && squareToken && !posDeviceIdentifier && pathname !== '/square/locations') {
      navigate('/square/locations');
    } else if (
      pos === Pos.Square
      && squareToken
      && posDeviceIdentifier
      && !pathname.match(/(\/app\/(.*))/g)
    ) {
      navigate('/app/attract');
    }
  }

  return (
    <>
      {children}
    </>
  );
};

SquareGuard.propTypes = {
  children: PropTypes.node
};

export default SquareGuard;
