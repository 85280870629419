export default {
  h1: {
    fontWeight: 500,
    fontSize: 55,
    letterSpacing: '-0.24px'
  },
  h2: {
    fontWeight: 500,
    fontSize: 45,
    letterSpacing: '-0.24px'
  },
  h3: {
    fontWeight: 500,
    fontSize: 38,
    letterSpacing: '-0.06px'
  },
  h4: {
    fontWeight: 500,
    fontSize: 31,
    letterSpacing: '-0.06px'
  },
  h5: {
    fontWeight: 500,
    fontSize: 25,
    letterSpacing: '-0.05px'
  },
  h6: {
    fontWeight: 500,
    fontSize: 22,
    letterSpacing: '-0.05px'
  },
  overline: {
    fontWeight: 500
  },
  fontFamily: 'Roboto',
  button: {
    textTransform: 'none'
  }
};
