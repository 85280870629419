import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { FC, useEffect, useState } from 'react';
import { Theme } from 'src/theme';
import { Typography } from '@material-ui/core';
import { StationStatus } from 'src/types/station';
import useSettings from 'src/hooks/useSettings';
import { api } from 'src/utils/api';
import { useSelector } from 'src/store';
import { SocketDataInterface } from 'src/types/socket';
import { playSound } from 'src/utils/common';
import clsx from 'clsx';
import { appInsights,severityLevel} from 'src/utils/appInsight';
import { status } from 'nprogress';

const useStyles = makeStyles((theme: Theme) => {
  const { customs: { sleepModeCover: { background, hue, saturation, lightness } }, palette: { background: { dark } } } = theme;
  const transparency = 96;
  const hsl = `${hue}, ${saturation}%, ${lightness}%`;

  const getTransitionKeyFrames = () => {
    const keyFrames = {};
    for (let i = 0; i <= 55; i++) {
      keyFrames[`${i}%`] = { background: `linear-gradient(to top, hsla(${hsl}, ${transparency}%) ${i}%, transparent)` };
    }
    return keyFrames;
  };

  return {
    overlay: {
      position: 'fixed',
      display: 'block',
      background: `linear-gradient(to top, hsla(${hsl}, ${transparency}%) 55%, transparent)`,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: background,
      zIndex: 9,
      cursor: 'pointer',
      animation: '$fadeBackground 2s ease-out',
    },
    '@keyframes fadeBackground': getTransitionKeyFrames(),
    textTransparent: {
      position: 'absolute',
      color: 'transparent',
    },
    zeroOpacity: {
      opacity: 0,
    },
    fullOpacity: {
      transitionProperty: 'opacity',
      transitionDuration: '0.5s',
      transitionTimingFunction: 'ease-out',
      opacity: 10,
    },
    text: {
      position: 'absolute',
      top: '53%',
      left: '50%',
      fontSize: '10rem',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      transitionProperty: 'color',
      transitionDuration: '2s',
      transitionTimingFunction: 'ease-out',
      color: dark,
    },
  };
});

const startSlash = process.env.REACT_APP_TYPE === 'BROWSER' ? '/' : '';
const sleepSound = `${startSlash}static/sounds/alcatel_jumping_off.mp3`;
const wakeUpSound = `${startSlash}static/sounds/rhodes_ubuntu.mp3`;

const SleepHandler: FC = () => {
  const { overlay, text, textTransparent, zeroOpacity, fullOpacity } = useStyles();
  const [isResting, setIsResting] = useState<boolean>(false);
  const { settings } = useSettings();
  const { socketData }: SocketDataInterface = useSelector((state) => state.socket);
  const stationStatus = socketData?.status;
  
  useEffect(() => {
    if (stationStatus === StationStatus.Resting) {
      playSound(sleepSound);
      if (!isResting) setIsResting(true);
    } else {
      if (isResting) setIsResting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationStatus]);

  const wakeUp = async () => {
    try {
      playSound(wakeUpSound);
      setIsResting(false);
      const { status } = await api(`Stations/${settings.stationId}/status/${StationStatus.Ready}`, 'patch');
      appInsights.trackEvent({ name: 'wakeUp', properties :{"StatusCode": status, "stationId": settings.stationId, "StationStatus": stationStatus} });
      if (status !== 200) setIsResting(true);
    } catch (e) {
      setIsResting(true);
      console.error(e);
      appInsights.trackException({ exception: e, properties:{"requestData": StationStatus}, severityLevel: severityLevel.Error });
    }
  };
  return (
    <div className={isResting ? clsx(overlay, fullOpacity) : zeroOpacity} onClick={wakeUp} >
      <Typography variant="h1" className={isResting ? text : textTransparent} paragraph>Resting Mode</Typography>
    </div >

  );
};
export default SleepHandler;
