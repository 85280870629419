import axios from 'axios';
import TokenService from 'src/utils/tokenService';
import { appInsights, severityLevel } from 'src/utils/appInsight';

const baseUrl: string = process.env.REACT_APP_API_URL;

interface HeaderTypeInterFace {
    Accept: string;
    'Content-Type': string;
    'Access-Control-Allow-Origin': string;
    'Authorization'?: string;
    'x-api-key'?: string;
    'client-id'?: number;
}

interface ResponseInterface {
    data: object | object[] | string[];
    status: string | number;
}

const defaultHeaders: HeaderTypeInterFace = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
};

const refreshTokenConstant = 'refreshToken';
const accessTokenConstant = 'accessToken';
const grantType = 'refresh_token';
const loginApiUrl = 'User/userLogin';

const api = async function (endpoint: string, method: string, params: object = {}, additionalHeaders: object = {}, url: string = baseUrl): Promise<any> {
    const getToken: string = localStorage.getItem('accessToken');
    if (getToken) {
        defaultHeaders.Authorization = `Bearer ${getToken}`;
    }

    const headers: object = {
        ...defaultHeaders,
        ...additionalHeaders,
    };

    const fullUrl: string = url + endpoint;

    if (method.toLowerCase() === 'get') {
        const resp: ResponseInterface = await axios
            .get(fullUrl, {
                params: params,
                headers: headers,
            })
            .then(function (response) {
                return { data: response.data, status: response.status };
            })
            .catch(async function (error) {
                appInsights.trackException({ exception: error, severityLevel: severityLevel.Error });
                if (error.response) {
                    if (error.response.status === 401) {
                        const refreshTokenResponse = await refreshToken(error);
                        if (refreshTokenResponse) {
                            if (refreshTokenResponse.status === 200) {
                                const accessToken = refreshTokenResponse.data.token;
                                TokenService.updateLocalAccessToken(accessToken, accessTokenConstant);
                                return await retryApiCall(endpoint, method, params, additionalHeaders);
                            } else if (refreshTokenResponse.status === 400) {
                                window.location.href = '/';
                            }
                        }
                    }
                    else {
                        return {
                            data: error.response.data,
                            status: error.response.status
                        };
                    }
                }
            });

        return resp;
    } else if (method.toLowerCase() === 'post') {
        const resp: ResponseInterface = await axios
            .post(fullUrl, params, {
                headers: headers,
            })
            .then(function (response) {
                return { data: response.data, status: response.status };
            })
            .catch(async function (error) {
                appInsights.trackException({ exception: error, severityLevel : severityLevel.Error});
                if (error.response) {
                    if (error.response.status === 401 && endpoint !== loginApiUrl) {
                        const refreshTokenResponse = await refreshToken(error);
                        if (refreshTokenResponse) {
                            if (refreshTokenResponse.status === 200) {
                                const accessToken = refreshTokenResponse.data.token;
                                TokenService.updateLocalAccessToken(accessToken, accessTokenConstant);
                                return await retryApiCall(endpoint, method, params, additionalHeaders);
                            } else if (refreshTokenResponse.status === 400) {
                                window.location.href = '/';
                            }
                        }
                    }
                    else {
                        return {
                            data: error.response.data,
                            status: error.response.status
                        };
                    }
                }
            });

        return resp;
    } else if (method.toLowerCase() === 'put') {
        const resp: ResponseInterface = await axios
            .put(fullUrl, params, {
                headers: headers,
            })
            .then(function (response) {
                return { data: response.data, status: response.status };
            })
            .catch(async function (error) {
                appInsights.trackException({ exception: error, severityLevel: severityLevel.Error });
                if (error.response) {
                    if (error.response.status === 401) {
                        const refreshTokenResponse = await refreshToken(error);
                        if (refreshTokenResponse) {
                            if (refreshTokenResponse.status === 200) {
                                const accessToken = refreshTokenResponse.data.token;
                                TokenService.updateLocalAccessToken(accessToken, accessTokenConstant);
                                return await retryApiCall(endpoint, method, params, additionalHeaders);
                            } else if (refreshTokenResponse.status === 400) {
                                window.location.href = '/';
                            }
                        }
                    }
                    else {
                        return {
                            data: error.response.data,
                            status: error.response.status
                        };
                    }
                }
            });

        return resp;
    } else if (method.toLowerCase() === 'patch') {
        const resp: ResponseInterface = await axios
            .patch(fullUrl, params, {
                headers: headers,
            })
            .then(function (response) {
                return { data: response.data, status: response.status };
            })
            .catch(async function (error) {
                appInsights.trackException({ exception: error, severityLevel: severityLevel.Error });
                if (error.response) {
                    if (error.response.status === 401) {
                        const refreshTokenResponse = await refreshToken(error);
                        if (refreshTokenResponse) {
                            if (refreshTokenResponse.status === 200) {
                                const accessToken = refreshTokenResponse.data.token;
                                TokenService.updateLocalAccessToken(accessToken, accessTokenConstant);
                                return await retryApiCall(endpoint, method, params, additionalHeaders);
                            } else if (refreshTokenResponse.status === 400) {
                                window.location.href = '/';
                            }
                        }
                    }
                    else {
                        return {
                            data: error.response.data,
                            status: error.response.status
                        };
                    }
                }
            });

        return resp;
    } else if (method.toLowerCase() === 'delete') {
        const resp: ResponseInterface = await axios
            .delete(fullUrl, {
                headers: headers,
                data: params,
            })
            .then(function (response) {
                return { data: response.data, status: response.status };
            })
            .catch(async function (error) {
                appInsights.trackException({ exception: error, severityLevel: severityLevel.Error });
                if (error.response) {
                    if (error.response.status === 401) {
                        const refreshTokenResponse = await refreshToken(error);
                        if (refreshTokenResponse) {
                            if (refreshTokenResponse.status === 200) {
                                const accessToken = refreshTokenResponse.data.token;
                                TokenService.updateLocalAccessToken(accessToken, accessTokenConstant);
                                return await retryApiCall(endpoint, method, params, additionalHeaders);
                            } else if (refreshTokenResponse.status === 400) {
                                window.location.href = '/';
                            }
                        }
                    }
                    else {
                        return {
                            data: error.response.data,
                            status: error.response.status
                        };
                    }
                }
            });

        return resp;
    }
};

const refreshToken = async function (error: any): Promise<any> {

    const loginUrl: string = baseUrl + loginApiUrl;
    const originalConfig = error.config;
    if (originalConfig.url !== loginUrl && error.response) {
        if (error.response.status === 401) {
            const refreshToken = TokenService.getLocalRefreshToken(refreshTokenConstant);
            const rs = await axios.post(loginUrl, {
                grant_type: grantType,
                refresh_token: refreshToken,
                username: '',
                password: '',
                rememberMe: false,
            })
                .then(function (response) {
                    return { data: response.data, status: response.status };
                })
                .catch(async function (error) {
                    appInsights.trackException({ exception: error, severityLevel: severityLevel.Error});
                    if (error.response.status) {
                        return {
                            data: error.response.data,
                            status: error.response.status
                        };
                    }
                });
            return rs;
        }
    }
}

const refreshAndStoreToken = async function (): Promise<any> {
    const loginUrl: string = baseUrl + loginApiUrl;
    const refreshToken = TokenService.getLocalRefreshToken(refreshTokenConstant);
    const rs = await axios.post(loginUrl, {
        grant_type: grantType,
        refresh_token: refreshToken,
        username: '',
        password: '',
        rememberMe: false,
    })
    .then(function (response) {
        return { data: response.data, status: response.status };
    })
    .catch(async function (error) {
        appInsights.trackException({ exception: error, severityLevel: severityLevel.Error});
        if (error.response.status) {
            return {
                data: error.response.data,
                status: error.response.status
            };
        }
    });

    if (rs.status === 200) {
        const accessToken = rs.data.token;
        TokenService.updateLocalAccessToken(accessToken, accessTokenConstant);
    } else if (rs.status === 400) {
        window.location.href = '/';
    }

    return rs.data.token;
}

const retryApiCall = async function (endpoint: string, method: string, params: object = {}, additionalHeaders: object = {}) {
    return await api(endpoint, method, params, additionalHeaders);
}

export { api, baseUrl, refreshAndStoreToken };
