export interface User {
  id: string;
  avatar: string;
  email: string;
  name: string;
  [key: string]: any;
}
export enum UserRoles {
  superAdmin = 'Super Admin',
  clientAdmin = 'Client Admin',
  storeManager = 'Store Manager',
  floorManager = 'Floor Manager',
  clerk = 'Clerk',
  actAsInternalService = 'Act As Internal Service',
  actAsService = 'Act As Service'

}