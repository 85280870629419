import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Modules } from 'src/types/module';
import { AccessControl } from 'src/types/accessControl';
import { AppThunk } from 'src/store';

interface ReduxStoreObject {
  accessControl?: AccessControl;
  actionType?: string;
}

const adminModules = Object.values(Modules).reduce((a, m) => {
  return { ...a, [m]: { delete: false, update: false, write: false, read: false } };
}, {});

const initialState: ReduxStoreObject = {
  accessControl: adminModules,
  actionType: '',
};

const slice = createSlice({
  name: 'accessControl',
  initialState,
  reducers: {
    get(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { accessControl } = action.payload;
      if (accessControl) {
        state.accessControl = accessControl;
        state.actionType = 'get';
      }
    },
  }
});

export const reducer = slice.reducer;

export const get = (accessControl: AccessControl): AppThunk => async (dispatch) => {
  dispatch(slice.actions.get({ accessControl }));
};

export default slice;