/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable  react-hooks/exhaustive-deps*/
import * as signalR from '@microsoft/signalr';
import React, { createContext, FC, ReactNode, useCallback, useEffect, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { setSocketData } from 'src/slices/socket';
import { useDispatch, useSelector } from 'src/store';
import { SocketDataInterface } from 'src/types/socket';
import { appInsights,severityLevel} from 'src/utils/appInsight';
import { refreshAndStoreToken } from 'src/utils/api';

export enum ConnectionState {
	Connected = 'Connected',
	Disconnected = 'Disconnected',
	Connecting = 'Connecting',
	Reconnecting = 'Reconnecting',
}
interface SocketContextInterface {
	activeConnection: any | null;
	socketData: { [key: string]: any; };
	emitSocketEvent: Function;
	disconnectSocket: Function;
	testEpc: Function;
}

interface SocketProviderInterface {
	children: ReactNode;
}

const SocketContext = createContext<SocketContextInterface>({
	activeConnection: {},
	socketData: {},
	emitSocketEvent: () => { },
	disconnectSocket: () => { },
	testEpc: () => { }
});

class CustomHttpClient extends signalR.DefaultHttpClient {
	constructor() {
    	super(console); // the base class wants a signalR.ILogger
  	}

	public async send(request: signalR.HttpRequest): Promise<signalR.HttpResponse> {
		request.headers = request.headers || {};
		request.headers['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
		
		try {
			const response = await super.send(request);
			return response;
		} catch (e) {
			appInsights.trackException({ exception: e, severityLevel: severityLevel.Error });
			
			if (e instanceof signalR.HttpError && e.statusCode === 401) {
				const newToken = await refreshAndStoreToken();
				request.headers['Authorization'] = `Bearer ${newToken}`;
			} else {
				throw e;
			}
		}

		return await super.send(request);
	}
}

export const SocketProvider: FC<SocketProviderInterface> = ({ children }) => {
	const dispatch = useDispatch();
	const [activeConnection, setActiveConnection] = useState(null);
	const { socketData }: SocketDataInterface = useSelector((state) => state.socket);
	const [connectedStation, setConnectedStation] = useState(null);
	const { isAuthenticated } = useAuth();
	const connectionStatus = activeConnection?.connection?.connectionState;
	const stationPermanentId = socketData?.permanentId;
	const subscribeToStationEvents = (activeConnection) => {
		activeConnection.on('StationAsync', (station) => {
			const socketData = {
				socketData: station,
				isConnected: true,
				connecting: false
			};
			dispatch(setSocketData(socketData));
			appInsights.trackTrace({ message:'SignalR: StationAsync Subscribed', properties:{"message": station}, severityLevel: severityLevel.Information });
		});
		
	};

	const registerForStationEvents = async (stationPermanentId) => {
		appInsights.trackEvent({ name: 'registerForStationEvents',properties:{"permanentId":stationPermanentId, "activeConnection": activeConnection}});
		await activeConnection.send('RegisterAsync', stationPermanentId);
		appInsights.trackTrace({ message:'SignalR: RegisterAsync EventEmitter Called', properties:{"stationPermanentId": stationPermanentId, "activeConnection": activeConnection}, severityLevel: severityLevel.Information });
	};

	const emitSocketEvent = async (stationPermanentId: string) => {
		appInsights.trackEvent({ name: 'emitSocketEvent',properties:{"permanentId":stationPermanentId, "activeConnection": activeConnection}});
		if (stationPermanentId) {
			setConnectedStation(stationPermanentId);
			await activeConnection.start();
			await registerForStationEvents(stationPermanentId);
		}
	};

	const initConnection = useCallback(() => {
		const accessToken: string = localStorage.getItem('accessToken');
		if (accessToken) {
			try {
				const makeConnection = new signalR.HubConnectionBuilder()
					.withUrl(`${process.env.REACT_APP_SOCKET_URL}/stationsHub`, {
						accessTokenFactory: () => {
							return localStorage.getItem('accessToken');
						},
						httpClient: new CustomHttpClient(),
					})
					.withAutomaticReconnect({
						nextRetryDelayInMilliseconds: retryContext => {
							// setIsConnectionRetrying(true);
							if (retryContext.previousRetryCount <= 10) {
								return 5000;
							} else {
								return 10000;
							}
						}
					})
					.build();
				setActiveConnection(makeConnection);
				appInsights.trackTrace({ message:'Station Hub Connected', properties:{"activeConnection": activeConnection}, severityLevel: severityLevel.Information });
			} catch (e) {
				appInsights.trackException({ exception: e, properties:{"activeConnection": activeConnection}, severityLevel: severityLevel.Error });
				return false;
			}
		} else {
			return false;
		}
	}, [useIsMountedRef]);

	// reconnect to station if connection lost.
	// if (activeConnection?.connection?.connectionState === 'Disconnected' && socketData?.permanentId) {
	// 	emitSocketEvent({ station: socketData?.permanentId });
	// }

	const disconnectSocket = () => {
		activeConnection.off('StationAsync');
		activeConnection.stop();
		setActiveConnection(null);
		setConnectedStation(null);
	};

	const getItem = (epc:string)=>{
		const epcPrefix = ['CD128', 'BC', '0D58AD', 'A1'];
		if(epcPrefix.some(e => epc.startsWith(e)))
			return { primaryIdentifier: epc }
		return { upc: epc }
	}

	const testEpc = ({ epc}: { epc: string; }) => {
		activeConnection.send('PostItemAsync', connectedStation, getItem(epc));
		appInsights.trackTrace({ message:'PostItemAsync EventEmitter Called', properties:{"epc": epc,"activeConnection": activeConnection}, severityLevel: severityLevel.Information });
	};

	if (activeConnection) {
		subscribeToStationEvents(activeConnection);

		activeConnection.on('stopAsync', () => {
			// dispatch(clearData()); // We do not need to clear data.
			// navigate('/'); // We do not need to redirect.
		});
	}

	useEffect(() => {
		if (!!activeConnection && stationPermanentId) {
			activeConnection.onreconnected(async () => {
				await registerForStationEvents(stationPermanentId);
				subscribeToStationEvents(activeConnection);
			});
		}
		return () => {
			if (!!activeConnection) {
				activeConnection.off('onreconnected');
			}
		};
	}, [activeConnection, stationPermanentId]);

	useEffect(() => {
		if (!activeConnection && isAuthenticated) {
			initConnection();
		}
		if (activeConnection) {
			return () => {
				disconnectSocket();
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeConnection, isAuthenticated]);

	// Removed useEffect and moved the IF condition in to open. As it was previously working. 
	useEffect(() => {
		if (connectionStatus === 'Disconnected' && stationPermanentId && isAuthenticated) {
			emitSocketEvent(stationPermanentId);
		}
	}, [connectionStatus, stationPermanentId, isAuthenticated]);

	return (
		<SocketContext.Provider value={
			{
				activeConnection,
				socketData: {},
				emitSocketEvent,
				disconnectSocket,
				testEpc
			}
		}>
			{children}
		</SocketContext.Provider>
	);
};

export default SocketContext;
