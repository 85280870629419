import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { Theme } from 'src/theme';
interface MainLayoutProps {
  children?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  bottomBar: {
    width: '100%',
    position: 'fixed',
    height: '15%',
    bottom: 0,
    background: theme.palette.background.default,
  },
  marginTop: {

  }
}));

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <div className={classes.content} data-name='child-container'>
          {children}
          <Outlet />
        </div>
      </div>
    </div>

  </React.Fragment>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
