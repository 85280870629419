export enum Modules {
  dashboard = 'dashboard',
  clients = 'clients',
  users = 'users',
  admins = 'admins',
  products = 'products',
  stores = 'stores',
  stationGroups = 'stationGroups',
  stations = 'stations',
  globalProfiles = 'globalProfiles',
  alertsAndNotifications = 'alertsAndNotifications',
  transactionHistory = 'transactionHistory',
}