import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type { Transaction, TransactionsRequestPayload } from 'src/types/transaction';
import { api } from 'src/utils/api';
import NProgress from 'nprogress';
import { appInsights,severityLevel} from 'src/utils/appInsight';

interface TransactionsState {
  transactions: Transaction[];
  error: boolean;
  message: string;
  loading: boolean;
}

const initialState: TransactionsState = {
  transactions: [],
  error: false,
  message: '',
  loading: true,
};

const slice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    getTransactions(state: TransactionsState, action: PayloadAction<TransactionsState>) {
      const { transactions, loading } = action.payload;
      state.transactions = [...transactions];
      state.loading = loading;
    },
    resetError(state: TransactionsState, action: PayloadAction<{}>) {

      state.error = false;
      state.message = '';
    },
    setLoading(state: TransactionsState, action: PayloadAction<{ loading: boolean; }>) {
      const { loading } = action.payload;

      state.loading = loading;
    }
  }
});

export const reducer = slice.reducer;

export const getTransactions = ({ minutes, stationId }: TransactionsRequestPayload): AppThunk => async (dispatch) => {
  NProgress.start();
  dispatch(slice.actions.setLoading({ loading: true }));
  const { status, data } = await api(`Stations/${stationId}/LatestTransactions/${minutes}`, 'get');
  appInsights.trackEvent({ name: 'getTransactions', properties:{"minutes": minutes, "stationId": stationId}});
  const getData = (data, error, message): TransactionsState => ({
    transactions: data,
    error,
    message,
    loading: false,
  });
  if (status === 200)
  {
    dispatch(slice.actions.getTransactions(getData(data, false, '')));
    appInsights.trackTrace({ message:'Transactions Loaded', properties:{"ErrorCode":status, "transanctions": data}, severityLevel: severityLevel.Information });
  }
    
  else
  {
    dispatch(slice.actions.getTransactions(getData([], true, 'An error while loading transactions!')));
    appInsights.trackTrace({ message:'Error Loading Transanctions', properties:{"minutes": minutes, "stationId": stationId}, severityLevel: severityLevel.Warning });
  }

  NProgress.done();
};

export const resetError = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.resetError({}));
};

export default slice;