import { combineReducers } from '@reduxjs/toolkit';
import { reducer as socketReducer } from 'src/slices/socket';
import { reducer as serviceWorkerReducer } from 'src/slices/serviceWorker';
import { reducer as transactionReducer } from 'src/slices/transaction';

const rootReducer = combineReducers({
  socket: socketReducer,
  serviceWorker: serviceWorkerReducer,
  transaction: transactionReducer,
});

export default rootReducer;
