import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsightConfig } from 'src/config';

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
    config: {
        instrumentationKey: appInsightConfig.instrumentationKey,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        },
        disableTelemetry: process.env.NODE_ENV == 'development',
        autoTrackPageVisitTime: true
    }
});

ai.appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
    envelope.tags = envelope.tags || [];
    envelope.tags.push({ 'ai.cloud.role': appInsightConfig.cloudRoleName });
});

ai.loadAppInsights();
export const severityLevel = SeverityLevel;
export const appInsights = ai.appInsights;

export function SetAuthenticatedUserContext(id){
    ai.setAuthenticatedUserContext(id);
}
