import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SocketState {
  socketData: object;
  connecting: boolean;
  isConnected: boolean;
}

const initialState: SocketState = {
  socketData: {},
  connecting: false,
  isConnected: false
};

const slice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    setSocketData(state: SocketState, action: PayloadAction<any>) {
      const { socketData, connecting, isConnected } = action.payload;
      state.socketData = socketData;
      state.isConnected = isConnected;
      state.connecting = connecting;
    },
    setPosDeviceIdentifier(state: SocketState, action: PayloadAction<any>) {
      const { posDeviceIdentifier } = action.payload;
      state.socketData = { ...state.socketData, posDeviceIdentifier };
    },
    setConnecting(state: SocketState, action: PayloadAction<any>) {
      const { connecting } = action.payload;
      state.connecting = connecting;
    },
    disconnect(state: SocketState) {
      state.isConnected = false;
      state.connecting = null;
      state.socketData = null;
    },
    clearData(state: SocketState) {
      state.socketData = null;
    }
  }
});

export const {
  setSocketData,
  setConnecting,
  disconnect,
  clearData,
  setPosDeviceIdentifier
} = slice.actions;
export const reducer = slice.reducer;
export default slice;
