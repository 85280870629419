import React, { lazy, Suspense, FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import GuestGuard from 'src/components/GuestGuard';
import LoadingScreen from 'src/components/LoadingScreen';
import MainLayout from 'src/layouts/MainLayout';
import AuthGuard from './components/AuthGuard';
import SquareAuthGuard from './components/SquareAuthGuard';

const LoginViewComponent = lazy(() => import('src/views/auth/LoginView'));
const MainHomeComponent = lazy(() => import('src/views/home'));
const AttractView = lazy(() => import('src/views/attract'));
const ItemDetailView = lazy(() => import('src/views/items/ItemDetailView'));
const ItemDetailForPayment =lazy(() => import('src/views/items/ItemDetailForPayment'));
const CheckoutComponent=lazy(() => import('src/views/items/ItemDetailForPayment/checkout'));
const PaymentTerminalComponent=lazy(() => import('src/views/items/ItemDetailForPayment/paymentTerminal'));
const CompleteOrderComponent=lazy(() => import('src/views/items/ItemDetailForPayment/completeOrder'));
const ValidatorDetailView=lazy(() => import('src/views/items/validatorDetailView'));
const StationTransView=lazy(() => import('src/views/items/stationTransaction'));
const ItemDetailForAssistance=lazy(() => import('src/views/items/ItemDetailForAssistance'));
const NotFoundView = lazy(() => import('src/views/errors/NotFoundView'));
const HomeView = lazy(() => import('src/views/home'));
const SquareLoginView=lazy(() => import('src/views/auth/SquareLoginView'));
const SquareLocationView=lazy(() => import('src/views/auth/connectSquareTerminalView'));
const DiviceCodeDisplay=lazy(() => import('src/views/auth/connectSquareTerminalView/terminalCodeDisplay'));
const TerminalConnectView=lazy(() => import('src/views/auth/connectSquareTerminalView/terminalConnected'));
const CallBackView=lazy(() => import('src/views/auth/SquareLoginView/callback'));

const renderRoutes = (): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    {
      <Routes>
        <Route path='/404' element={<NotFoundView />}> </Route>
        <Route path='/' element={<GuestGuard> <LoginViewComponent /> </GuestGuard>}> </Route>
        <Route path='/login-unprotected' element={<LoginViewComponent />}> </Route>
        <Route path='/access-code' element={<AuthGuard><MainHomeComponent /> </AuthGuard>}> </Route>
        <Route path='/app/home' element={<AuthGuard><HomeView /> </AuthGuard>}> </Route>
        <Route path='/square/auth' element={<AuthGuard><SquareLoginView /> </AuthGuard>}> </Route>
        <Route path='/square/locations' element={<AuthGuard><SquareLocationView /> </AuthGuard>}> </Route>
        <Route path='/square/device-code' element={<AuthGuard><DiviceCodeDisplay /> </AuthGuard>}> </Route>
        <Route path='/square/terminal-connected' element={<AuthGuard><TerminalConnectView /> </AuthGuard>}> </Route>
        <Route path='/app/square/callback' element={<AuthGuard><CallBackView /> </AuthGuard>}> </Route>
        <Route  path="*"  element={<Navigate to="/404" />}> </Route>

        {/* All child routes /app */}
        <Route path='/app' element={<AuthGuard><SquareAuthGuard><MainLayout /></SquareAuthGuard></AuthGuard>}>
          <Route path='/app/attract' element={<AttractView></AttractView>}> </Route>
          <Route path='/app/item-details' element={<ItemDetailView />}> </Route>
          <Route path='/app/item-details-for-payment' element={<ItemDetailForPayment />}> </Route>
          <Route path='/app/item-details-for-payment/checkout' element={<CheckoutComponent />}> </Route>
          <Route path='/app/item-details-for-payment/payment-terminal' element={<PaymentTerminalComponent />}> </Route>
          <Route path='/app/item-details-for-payment/complete-order' element={<CompleteOrderComponent />}> </Route>
          <Route path='/app/validator-item-details' element={<ValidatorDetailView />}> </Route>
          <Route path='/app/stations-transactions' element={<StationTransView />}> </Route>
          <Route path='/app/assist-transaction' element={<ItemDetailForAssistance />}> </Route>
          <Route  path="*"  element={<Navigate to="/404" />}> </Route>
        </Route>

      </Routes>

    }
  </Suspense>
  
);

const CheckoutRoutes: FC = () => {
  return (
    renderRoutes()
  );
};

export default CheckoutRoutes;
