export enum MenuOptions {
  ResetStation = 'ResetStation',
  Attract = 'Attract',
  ItemCount = 'ItemCount',
  ItemDetail = 'ItemDetail',
  CompleteTime = 'CompleteTime',
  ReprintReceipt = 'ReprintReceipt',
  ManualStart = 'ManualStart',
  DirectionArrow = 'LeftDirectionArrow',
  AssistTransaction = 'AssistTransaction',
  Assisting = 'Assisting',
  FacialStart = 'FacialStart',
  BarcodeAtStart = 'BarcodeReaderStart',
  EmailReceipt = 'EmailReceipt',
  PaymentTerminalLocation = 'PaymentTerminalLocation'
}

export enum ItemStatus {
  New = 'New',
  Voided = 'Voided',
  Returned = 'Returned',
  Purchased = 'Purchased',
}