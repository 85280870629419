import {
  colors,
  responsiveFontSizes
} from '@material-ui/core';
import { createTheme as createMuiTheme } from '@material-ui/core/styles';
import type { Theme as MuiTheme } from '@material-ui/core/styles/createTheme';
import type {
  Palette as MuiPalette,
  TypeBackground as MuiTypeBackground
} from '@material-ui/core/styles/createPalette';
import type { Shadows as MuiShadows } from '@material-ui/core/styles/shadows';
import _ from 'lodash';
import { THEMES } from 'src/constants';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';
import { appInsights,severityLevel} from 'src/utils/appInsight';

interface TypeBackground extends MuiTypeBackground {
  dark: string;
}

interface Palette extends MuiPalette {
  background: TypeBackground;
}

export interface Theme extends MuiTheme {
  name: string;
  palette: Palette;
  customs?: any;
}

type Direction = 'ltr' | 'rtl';

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  theme?: string;
}

interface ThemeOptions {
  name?: string;
  direction?: Direction;
  typography?: Record<string, any>;
  overrides?: Record<string, any>;
  palette?: Record<string, any>;
  shadows?: MuiShadows;
  customs?: any;
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    },
  }
};

const overrides = {
  MuiTableCell: {
    head: {
      fontSize: '2.5rem',
    },
    body: {
      fontSize: '2.2rem',
    }
  },
  MuiInputBase: {
    root: {
      height: '6rem',
    },
    input: {
      '&::placeholder': {
        opacity: 1,
        color: colors.blueGrey[600],
      },
    },
  },
  MuiButton: {
    root: {
      fontSize: '2rem',
      padding: '14px 16px',
    },
    outlined: {
      padding: '14px 16px',
    }
  },
  MuiInputLabel: {
    root: {
      fontSize: '2rem',
    },
    outlined: {
      transform: 'translate(14px, 32px) scale(1)',
      '&$shrink': {
        transform: 'translate(14px, -11px) scale(0.75)',
      }
    },

  },
  MuiFormHelperText: {
    root: {
      fontSize: '1.5rem',
    },
  },
  MuiOutlinedInput: {
    input: {
      padding: '1.8rem 1rem'
    },
    root: {
      fontSize: '2rem',
    },
  },
  MuiSelect: {
    select: {
      '&:focus': {
        padding: '30px'
      }
    }
  },
  MuiAutocomplete: {
    popupIndicator: {
      bottom: '10px'
    },
    popupIndicatorOpen: {
      bottom: '10px'
    },
    noOptions: {
      fontSize: '2rem',
    },
    option: {
      fontSize: '2rem',
    }
  },
  MuiSwitch: {
    root: {
      width: '7rem',
      height: '3.7rem'
    },
    thumb: {
      width: '3.2rem',
      height: '3rem'
    },
    switchBase: {
      // Controls default (unchecked) for the thumb
      width: '4rem',
      top: '-0.3rem',
      left: '-0.2rem',
    },
    colorSecondary: {
      '&$checked': {
        // Controls checked/coloured for the thumb
        width: '8.5rem',
      }
    },
    track: {
      borderRadius: '1rem'
    }
  },

  // containerRoot: {
  //   "& .MuiSnackbar-root": {
  //     "& .MuiSnackbarContent-root": {
  //       height: "200px"
  //     }
  //   }
  // }
};

const themesOptions: ThemeOptions[] = [
  {
    name: THEMES.LIGHT,
    overrides,
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white
      },
      primary: {
        main: '#212221'
      },
      secondary: {
        main: '#80C41C'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      }
    },
    shadows: softShadows,
    customs: {
      button: {
        text: {
          color: '#000000'
        }
      },
      keyBoard: {
        keyShadow: '#adb0bb',
      },
      backgroundText: '#adadad',
      attractFont: '#4b48f0',
      attractBg: '#93c4f9',
      textOverLightShades: '#282C34',
      textOverDarkShades: '#EEEEEE',
      colors: {
        purple: '#4b48f0',
        white: '#f4f6f8',
        black: '#111111',
        orange: '#FF4500',
        lightOrange: '#FF9900',
        blue: '#1f58ad',
      },
      transaction: {
        peachOrange: 'hsla(35, 100%, 92%, 0.5)',
        orange: 'hsla(36, 100%, 78%, 0.5)',
        whisper: '#EEEEEE',
        lightGreen: 'hsla(102, 100%, 93%, 0.5)',
        green: 'hsla(102, 55%, 73%, 0.5)',
        lightRed: 'hsla(360, 75%, 54%, 0.75)',
        red: 'hsla(360, 98%, 38%, 1.00)',
        grey: 'hsla(240, 7%, 85%, 1)'
      },
      sleepModeCover: {
        hue: 216,
        saturation: 88,
        lightness: 27,
        background: 'rgba(255,255,255,0)',
      }
    }
  },
  {
    name: THEMES.DARK,
    overrides,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34'
      },
      primary: {
        main: '#80C41C'
      },
      secondary: {
        main: '#80C41C'
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb'
      }
    },
    shadows: strongShadows,
    customs: {
      button: {
        text: {
          color: '#FFFFFF'
        }
      },
      keyBoard: {
        keyShadow: '#111111',
      },
      backgroundText: '#adb0bb',
      attractFont: '#282C34',
      attractBg: '#A5D562',
      textOverLightShades: '#282C34',
      textOverDarkShades: '#EEEEEE',
      colors: {
        purple: '#4b48f0',
        white: '#f4f6f8',
        black: '#111111',
        orange: '#FF7F50',
        lightOrange: '#FF9900',
        blue: '#1f58ad',
      },
      transaction: {
        peachOrange: 'hsla(35, 100%, 92%, 0.5)',
        orange: 'hsla(36, 100%, 78%, 0.5)',
        whisper: '#EEEEEE',
        lightGreen: 'hsla(102, 100%, 93%, 0.5)',
        green: 'hsla(102, 55%, 73%, 0.5)',
        lightRed: 'hsla(360, 75%, 54%, 0.75)',
        red: 'hsla(360, 98%, 38%, 1.00)',
        grey: 'hsla(240, 1%, 31%, 1)'
      },
      sleepModeCover: {
        hue: 216,
        saturation: 70,
        lightness: 50,
        background: 'rgba(255,255,255,0)',
      }
    }
  },
];

export const createTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);
  appInsights.trackEvent({ name: 'createTheme', properties :{"Theme" : config.theme} });

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    appInsights.trackTrace({ message:'Theme is not Valid', properties:{"theme": config.theme}, severityLevel: severityLevel.Warning });
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge(
      {},
      baseOptions,
      themeOptions,
      { direction: config.direction }
    )
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme as Theme;
};
